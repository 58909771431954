import { Component, Input } from '@angular/core';
import { PaymentMethod } from '../../registration/shared/models/payment-method';
import { PaymentMethodService } from '../../registration/shared/payment-method-service/payment-method.service';
import { IbanService } from '../../registration/shared/iban-service/iban.service';

@Component({
    selector: 'app-payment-select',
    templateUrl: './payment-select.component.html',
    styleUrls: ['./payment-select.component.scss'],
})
export class PaymentSelectComponent {
    @Input() allowedPaymentMethods: Array<PaymentMethod>;
    @Input() bottomText: string;

    private _countryName: string;
    @Input() set countryName(value: string) {
        this.paymentMethodList = this.paymentMethodService.GetPaymentMethodsByCountry(value);
        this._countryName = value;
    }
    get countryName(): string {
        return this._countryName;
    }

    public selectedPaymentMethod: PaymentMethod;
    public sepaDirectDebitIban: string = '';
    public sepaDirectDebitName: string = '';

    paymentMethodList: Array<PaymentMethod | PaymentMethod[]>;

    constructor(private paymentMethodService: PaymentMethodService, private ibanService: IbanService) {}

    /**
     * Validate and set payment method in enrollment
     * @param method
     */
    setPaymentMethod(method: PaymentMethod) {
        if (method == PaymentMethod.MastercardComingSoon) {
            return;
        }

        if (Array.isArray(method)) {
            if (method.indexOf(this.selectedPaymentMethod) == -1) {
                this.setPaymentMethod(method[0]);
            }
        } else if (this.hasPaymentMethod(method)) {
            this.selectedPaymentMethod = method;
        }
    }

    /**
     * Return true if subscriptionType contains this paymentmethod
     * @param paymentMethod
     */
    hasPaymentMethod(paymentMethod: PaymentMethod | PaymentMethod[]): boolean {
        if (Array.isArray(paymentMethod)) {
            return paymentMethod.some((r) => this.allowedPaymentMethods.indexOf(r) > -1);
        }

        return this.allowedPaymentMethods.indexOf(paymentMethod) > -1;
    }
  
    hasMultiplePaymentMethods(item: PaymentMethod | Array<PaymentMethod>): boolean {
        return Array.isArray(item) && (item.filter(subitem => this.hasPaymentMethod(subitem)).length > 1);
    }

    isPaymentButtonSelected(item: PaymentMethod | Array<PaymentMethod>): boolean {
        if (Array.isArray(item)) {
            return item.indexOf(this.selectedPaymentMethod) > -1;
        } else {
            return this.selectedPaymentMethod == item;
        }
    }

    getPaymentItemName(item: PaymentMethod | Array<PaymentMethod>): string {
        return this.paymentMethodService.GetPaymentItemName(item);
    }

    getPaymentItemString(item: PaymentMethod): string {
        return this.paymentMethodService.GetPaymentItemString(item);
    }

    isSepaDirectDebit(item: PaymentMethod): boolean {
        return item == PaymentMethod.SepaDirectDebit;
    }

    public isValid(): boolean {
        return (
            this.selectedPaymentMethod != null &&
            this.selectedPaymentMethod != PaymentMethod.Unknown.valueOf() &&
            (this.selectedPaymentMethod != PaymentMethod.SepaDirectDebit ||
                (this.sepaDirectDebitIban.length > 0 &&
                    this.ibanService.isValidIBAN(this.sepaDirectDebitIban) &&
                    this.sepaDirectDebitName.length > 0 &&
                    this.ibanService.isAllowedCountry(this.sepaDirectDebitIban, this.countryName)))
        );
    }

    // Ideal stuff
    isIdeal(item: PaymentMethod): boolean {
        return item == PaymentMethod.Ideal;
    }
}
