import { PaymentMethod } from './payment-method';
export class PaymentRequest {
    customerId: string;
    hash: string;
    paymentMethod: PaymentMethod;

    constructor(customerId: string, hash: string, paymentmethod: PaymentMethod) {
        this.customerId = customerId;
        this.hash = hash;
        this.paymentMethod = paymentmethod;
    }

    ToJson(): string {
        return JSON.stringify(this);
    }
}

export class PaymentRequestSepaDirectDebit {
    customerId: string;
    hash: string;
    subscriptionId: string;
    iban: string;
    name: string;

    constructor(data: Partial<PaymentRequestSepaDirectDebit>) {
        Object.assign(this, data);
    }

    ToJson(): string {
        return JSON.stringify(this);
    }
}
